import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';

export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  created() {
    this.$store.dispatch('productCategory/getItems');
    this.$store.dispatch('options/getItems');
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'ProductCategory',
    returnTo: 'ProductCategories'
  }),
  methods: {
    getDependencies(data) {
      return [];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
