import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    displayName: {
      type: MultiLanguageInput,
      props: {
        maxlength: 64,
        type: 'text',
        caption: 'common.displayName'
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: MultiLanguageInput,
      props: {
        required: true,
        type: 'textarea',
        caption: 'common.description'
      },
      rules: [{ validator: validatorRequire }]
    },
    wooCommerceDisplayType: {
      type: Select,
      defaultValue: 0,
      props: {
        caption: 'productCategory.wooCommerceDisplayType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('WooCommerceDisplayType')
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    parentID: {
      type: Select,
      defaultValue: vue.$store.getters['productCategory/getDefaultSelectValueProductCategoryID'],
      props: {
        caption: 'common.parent',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['productCategory/isLoading'],
        options: vue.$store.getters['productCategory/loadedItems']
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
